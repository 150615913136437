import firebase from 'firebase';
import firestore from 'firebase/firestore'

const settings = {timestampsInSnapshots: true};

// Informações do projeto obtida através do console do Google Firebase

const config = {
    apiKey: "AIzaSyD3DkyeYDbCPFPa1pfVEHzYTDRt9PYFuI4",
    authDomain: "arctica-crud-react.firebaseapp.com",
    projectId: "arctica-crud-react",
    storageBucket: "arctica-crud-react.appspot.com",
    messagingSenderId: "725642030650",
    appId: "1:725642030650:web:0fb6258177428fc4cb8f03"
};
firebase.initializeApp(config);

firebase.firestore().settings(settings);

export default firebase;